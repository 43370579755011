import React from 'react';
import growth from '../../assets/images/growth.jpeg'
function HeroAbout() {
    return (
        <>
            <div className="appie-about-top-title-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="appie-about-top-title">
                                {/* <h2 className="title">The leading people Management platform</h2>
                                <p>We offer you a panoply of cutting-edge options.</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="appie-about-page-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-about-page-content">
                                <h3 className="title">
                                    Our vision is creativity, innovative and desire to challenge
                                </h3>
                                <p>
                                In June 2011, M.A.V VIDYASHRAM was founded by Mr. M. ARULVENDAN,
B.E., to provide quality education by combining the traditions of Indian education with
modern educational techniques. Smt. SULOCHANA ARULVENDAN, B.A., is the
Correspondent of the school. The school is managed by Murugammai Educational Trust.
While the Chairman governs the organization of the school, the Correspondent takes care of
the administration. The Principal Smt. S. UMA MAHESWARI M.A., B.Ed., heads the
academic process and supervises the functioning of the school. With its steady and straight
growth, it was upgraded to Grade X in the year 2015.</p>
<p>The school has been recognized and affiliated to the Central Board of Secondary
Education (CBSE), New Delhi with effect from December 29, 2015. The first batch of
Grade X students appeared for the Secondary Board Examination in March, 2017 and
brought in 100% success . From then the trend continues every year. M.A.V.Vidyashram
was upgraded to Senior Secondary during the academic year 2019. The first batch of our
Grade 12 students will be appearing for Board exams in 2021-22. The school offers Physics,
Chemistry, Biology, Computer Science, Maths, English, Business studies, Accountancy and
Economics as elective subjects.</p>
<p>Our school’s motto is “Learning for life - ACADEMICS, VALUES AND
CHARACTER” with “KNOWLEDGE AND VIRTUE”. The motto manifests itself into our
classrooms, learning tools and activities through the year. We at M.A.V VIDYASHRAM,
believe in creating an environment for holistic learning that inspires and empowers every
child to reach his/her goals and dreams.</p>
<p>We provide an empowering child-centric environment with numerous learning opportunities
and best teaching practices which prepare children for global citizenship.
We aim to provide high quality education for all the students. Students are encouraged
to develop their individual talents by being engaged in a variety of activities. A conducive
yet highly competitive learning environment ensures that the best in the students is brought
out.</p>
<p>By the end of the year we will provide more opportunities to kindle creative and
critical thinking in students by arranging intensive training programmes for our teachers.
                                </p>
                               <img src={growth} style={{marginLeft:'20%'}} className="pt-30"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HeroAbout;
