import React from 'react';

function Forms() {
    const [formData, updateFormData] = React.useState([]);

    const handleChange = (e) => {
        updateFormData({
            ...formData,

            // Trimming any whitespace
            [e.target.name]: e.target.value.trim()
        });
    };
    const handleFormSubmit = (event) => {
        
        let data = event.target
        
    }
    return (
        <>
            <section className="contact-section pt-50">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="contact--info-area">
                                <h3>Get in touch</h3>
                                <p>Looking for help? Fill the form and start a new adventure.</p>
                                <div className="single-info">
                                    <h5>Headquaters</h5>
                                    <p>
                                        <i className="fal fa-home"></i>
                                        No. 10A, Vaigai Street Extension,Gurusamy Nagar,Sembakkam,
                                        <br /> Chennai-600073
                                    </p>
                                </div>
                                <div className="single-info">
                                    <h5>Phone</h5>
                                    <p>
                                        <i className="fal fa-phone"></i>
                                        73977 34625
                                        <br />
                                        73977 34629
                                        <br />
                                        95662 01323
                                    </p>
                                </div>
                                <div className="single-info">
                                    <h5>Email</h5>
                                    <p>
                                        <i className="fal fa-envelope"></i>
                                        info@mavvidyashram.com
                                        <br />
                                        mavvidyashram@gmail.com
                                    </p>
                                </div>
                                <div className="ab-social">
                                    <h5>Working Hours</h5>
                                    Morning session : 08.50 a.m. to 01.00 p.m.
                                    <br />
                                    Afternoon session : 01.30 p.m. to 04.30 p.m
                                    <br />
                                    Sunday: Closed
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="contact-form">
                                <h4>Request for bonafide certificate</h4>
                                <p>Send us an email if you have any queries. We will get back to you shortly</p>
                                <form action="email.php" method="post" className="row">
                                    <div className="col-md-6">
                                        <select className="gender-select custom-select" name="studentGender" onChange={handleChange}>
                                            <option selected>Select Gender</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                        </select>
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" name="studentName" placeholder="Enter student name" onChange={handleChange} />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" name="fathersName" placeholder="Enter fathers name" onChange={handleChange} />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" name="mothersName" placeholder="Enter mothers name" onChange={handleChange} />
                                    </div>
                                    <div className="col-md-6">
                                        <select className="grade-select custom-select" name="gradeSelection" onChange={handleChange}>
                                            <option selected>Select Grade</option>
                                            <option value="Preschool">Preschool</option>
                                            <option>Junior KG</option>
                                            <option>Senior KG</option>
                                            <option>Grade I</option>
                                            <option>Grade II</option>
                                            <option>Grade III</option>
                                            <option>Grade IV</option>
                                            <option>Grade V</option>
                                            <option>Grade VI</option>
                                            <option>Grade VII</option>
                                            <option>Grade VIII</option>
                                            <option>Grade IX</option>
                                            <option>Grade X</option>
                                        </select>
                                    </div>
                                    <div className="col-md-6">
                                        <select className="custom-select" name="sectionSelection" onChange={handleChange}>
                                            <option selected>Select Section</option>
                                            <option>A</option>
                                            <option>B</option>
                                            <option>C</option>
                                            <option>D</option>
                                            <option>E</option>
                                        </select>
                                    </div>

                                    <div className="col-md-12">
                                        <input type="text" name="studentAddress" placeholder="Enter address" onChange={handleChange}/>
                                    </div>
                                    <div className="col-md-12">
                                        <textarea
                                            name="bonafidePurpose"
                                            placeholder="Enter purpose"
                                            onChange={handleChange}
                                        ></textarea>
                                    </div>
                                    <div className="col-md-6 text-right">
                                    <button type="submit" className="main-btn" name="submit" value="bonafide">Request Bonafide</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="bisylms-map">

            </div>
        </>
    );
}

export default Forms;
