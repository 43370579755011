import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AboutUs from './components/AboutUs';
import Contact from './components/Contact';
import Error from './components/Error';
import Loader from './components/Helper/Loader';
import ScrollToTop from './components/Helper/ScrollToTop';
import HomeEight from './components/HomeEight';
import News from './components/News';
import SingleNews from './components/News/SingleNews';
import Service from './components/Service';
import Facilities from './components/Facilities'
import Affiliation from './components/Affiliation'
import NOC from './components/NOC'
import Recognition from './components/Recognition'
import Buildingsafety from './components/Buildingsafety'
import Smc from './components/Smc'
import Results from './components/Results'
import Pta from './components/Pta'
import Safety from './components/Safety'
import Trustdeed from './components/Trustdeed'
import Academiccalendar from './components/Academiccalendar'
import ManagementComp from './components/Management';
import GrowthComp from './components/GrowthandAchievement';
import Bonafide from './components/Emav/bonafide';
import Leaveletter from './components/Emav/leaveletter';
import Updateform from './components/Emav/updateform';
import Itform from './components/Emav/itform';
import Thankyou from './components/Emav/thankyou'


function Routes() {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    });


/*eslint-disable no-restricted-globals */

    return (
        <>
            {loading && (
                <div className={`appie-loader ${loading ? 'active' : ''}`}>
                    <Loader />
                </div>
            )}
            <div className={`appie-visible ${loading === false ? 'active' : ''}`}>
                    <ScrollToTop>
                        <Switch>
                            <Route exact path="/" component={HomeEight} />
                            <Route exact path="/news" component={News} />
                            <Route exact path="/facilities" component={Facilities} />
                            <Route exact path="/news/single-news" component={SingleNews} />
                            <Route exact path="/service" component={Service} />
                            <Route exact path="/about-us" component={AboutUs} />
                            <Route exact path="/contact" component={Contact} />
                            <Route exact path="/management-desk" component={ManagementComp} />
                            <Route exact path="/growth-and-achievement" component={GrowthComp} />
                            <Route exact path="/error" component={Error} />
                            <Route exact path="/bonafide" component={Bonafide} />
                            <Route exact path="/leave-letter" component={Leaveletter} />
                            <Route exact path="/update-form" component={Updateform} />
                            <Route exact path="/it-form" component={Itform} />
                            <Route exact path="/thankyou" component={Thankyou} />
                            {/* affiliation */}

                            <Route exact path="/affiliation" component={Affiliation} />
                            <Route exact path="/noc" component={NOC} />
                            <Route exact path="/recognition" component={Recognition} />
                            <Route exact path="/safety" component={Safety} />
                            <Route exact path="/trustdeed" component={Trustdeed} />
                            <Route exact path="/academiccalendar" component={Academiccalendar} />
                            <Route exact path="/smc" component={Smc} />
                            <Route exact path="/results" component={Results} />
                            <Route exact path="/pta" component={Pta} />
                            <Route exact path="/buildingsafety" component={Buildingsafety} />

                            {/* <Route component={Error} /> */}
                        </Switch>
                    </ScrollToTop>
            </div>
        </>
    );
}

export default Routes;
