import React from 'react';
import useToggle from '../../../Hooks/useToggle';
import BackToTop from '../../BackToTop';
import FooterHomeOne from '../../HomeEight/FooterHomeEight';
import Drawer from '../../Mobile/Drawer';
import HeaderAbout from '../../HomeEight/HeaderHomeEight';

import Forms from './Forms';

function Updateform() {
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderAbout drawer={drawer} action={drawerAction.toggle} />

            <Forms />
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default Updateform;
