import React from 'react';

function HeroAbout() {
    return (
        <>
            <div className="appie-about-top-title-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="appie-about-top-title">
                                {/* <h2 className="title">The leading people Management platform</h2>
                                <p>We offer you a panoply of cutting-edge options.</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="appie-about-page-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-about-page-content customLineHeight">
                                <h3 className="title">
                                CHAIRMAN'S DESK
                                </h3>
                                <h4> -  MR. M.ARULVENDHAN, B.E CIVIL ENGINEER</h4>
                                <br/>
                                <p>
                                    “The function of education is to teach one to think intensively and to think critically. Intelligence plus character – that is the goal of true education” <br />– Martin Luther King Jr.
                                    <p>Mr. M. ARULVENDAN, B.E., is the Founder Chairman of M.A.V Vidyashram. Mr. M. Arulvendan’s visionary zeal and enthusiasm in promoting and driving excellence in primary and secondary school education has benefited hundreds of students who have passed through the portals of MAV Vidyashram. The founder has not only brought name and fame to his family but also to the society at large. The founder’s nobility and generosity has made this institution a prominent one. The school has been providing high standard of education, excellent teaching and learning facilities. Good infrastructure, qualified teachers, specialized co-curricular activities and efficient administration are the four reasons for the success of M.A.V Vidyashram. Thus, Mr. M. Arulvendan has made this institution as a seat of learning and a centre of sports as well. He is of the opinion that “Educating the mind without educating the heart is no education at all”. He further says that “Education must be seen as a liberating process”. To him, the right ways to educate the students are:</p>
                                    <br />
                                    <ul>


                                        <li>Students must be educated to make free conversation.</li>
                                        <li>Students must be educated to talk politely with elders and teachers.</li>
                                        <li>Students must be educated to solve problems themselves.</li>
                                        <li>Students must be educated to take right and clear decisions.</li>
                                        <li>Students must be educated to assist those with special needs and know others’ feelings like their own.</li>
                                        <li>Students must be educated to make them understand what they are.</li>
                                        <li>Students must be educated to form the habit of questioning how and why.</li>
                                        <li>Students must be educated by knowing their feelings.</li>
                                        <li>Finally, students must be educated to relax by discarding their depressed mood such as sadness, anger, fear, worry, etc.</li>
                                    </ul>
                                </p>
                                {/* Correspondent Desk */}
                                    <br/>
                                    <h3 className="title">
                                    CORRESPONDENT'S DESK
                                </h3>
                                <h4> - SMT. SULOCHANA ARULVENDAN, B.A.,</h4>
                                <br/>
                                <p>
                                “In human being there is an urge for achieving excellence in his / her work. He/She yearns for outlets for his / her skill, knowledge and abilities.“</p>
                                    <p>Communication is an institution’s life blood. It thrives in an atmosphere of mutual respect and dignity. As part of our transparent policy and constant internal governance effort, we are very happy to communicate to you through this mode / website to inform, engage, enrich and empower all the stake holders comprising students, parents, teaching and non-teaching staff to take the institution forward. M.A.V. family inherited a high tradition imbibed with the qualities and virtues of determination, devotion, dedication to wards’ development and development of education in the country. It is now on in its seventh year. Today, it is a renowned educational institution. These seven years can be termed as its years of childhood (nascent stage), characterized by innocence, enthusiasm, continuity and change (be it the external environment or the internal governance matrix). Over the last seven years, our role has grown and so has the size of our students and staff.</p>
                                    <br />
                                   <p>We believe that our teachers are the true friends of students. They are able to guide students in their physical, social, intellectual, emotional and moral development. We have been able to build a successful and respectful space for the teachers, which they truly deserve. Our teachers have a passion, aspiration and strong conceptual skills required for the teaching profession. At this juncture, we rededicate ourselves to work for the further growth and development of the school and the Nation.
                                </p>
                                {/* Principal Desk */}
                                <br/>
                                <br/>
                                    <h3 className="title">
                                    PRINCIPAL'S DESK
                                </h3>
                                <h4> - SMT. S. UMA MAHESWARI, M.A., B.ED.,</h4>
                                <br/>
                           
                                <p>
                                M.A.V Vidyashram has established itself as a premier institution of learning and is providing quality education and co-curricular activities along with sports and games to the students. We thank you all for trusting M.A.V. Vidyashram and we are committed to the excellence in educating the minds and hearts. We are aware that:</p>
                                <br/>
                                <ul>
                                <li>Modern education is all about helping the child to realize and explore his/her full potential and talent. </li>
<li>Our children represent our hopes and dreams.  </li>
<li>Parents, students and school authorities are a team and complement each other’s efforts. </li> 
<li>Education is the key to success in life and the school makes a long-lasting impact on the students.</li>
<li>Education is not a process of giving knowledge for a future job but a lifelong process which creates an understanding of moral and ethical values to guide one’s life and make our students the future hope of the country.</li>
                                
                            </ul><br/>
                            <p>Therefore, we, at M.A.V Vidyashram, create an atmosphere of reverence for education and a healthy environment where study and sports activities will mould the students and spur them on to be the bright and the best. To make knowledge meaningful and the teaching-learning process easy, we aspire to be the best by using solid research based instructional strategies so that the needs of all the students are met. It is truly our goal to not just increase and maintain student achievement at high level but to change their life in the process. In addition, a constant effort to update the faculty is made by deputing teachers to refresher programmes.</p>
                            <br/><p>A harmoniously blend of a committed and supportive management, dedicated teachers and caring / cooperative parents has made M.A.V Vidyashram as a child-centric school. It is therefore but natural to find in this ambience the intensive use of a variety of thinking activities, strategies and group dynamics so that the class remains alive at all times. Thus, we believe that we have been fulfilling the needs of every child in the form of strong academics, varied extra-curricular options including sports and value based education, the kind of education which can change a child’s world</p>
                            <br/>
                            <p>“Progress is not an accident but a necessity; it is a part of nature” said Herbert Spencer. We believe in it, work for it and would achieve it. At this juncture, we would like to mention that students, parents and teachers all play an important role in student's life and through a collaborative effort, students can achieve their fullest potential and transform as the future leaders of this world.</p>
                                    <br />
                                   <p>We believe that our teachers are the true friends of students. They are able to guide students in their physical, social, intellectual, emotional and moral development. We have been able to build a successful and respectful space for the teachers, which they truly deserve. Our teachers have a passion, aspiration and strong conceptual skills required for the teaching profession. At this juncture, we rededicate ourselves to work for the further growth and development of the school and the Nation.
                                </p>
                            </div>
    
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HeroAbout;
