import React, { lazy, Suspense } from 'react';
import Slider from 'react-slick/lib/slider';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import FooterHomeEight from './FooterHomeEight';
import HeaderHomeEight from './HeaderHomeEight';


function HomeEight() {
    const [drawer, setDrawer] = useToggle(false);
    const HeroHomeEight = lazy(() => import('../HomeEight/HeroHomeEight'));
    const TestimonialHomeEight = lazy(() => import('../HomeEight/TestimonialHomeEight'));
    const AboutHomeEight = lazy(() => import('../HomeEight/AboutHomeEight'));
    const TeamHomeEight = lazy(() => import('../HomeEight/TeamHomeEight'));


    return (
        <>
            <Drawer drawer={drawer} action={setDrawer.toggle} />
            <Suspense fallback = {<div>Loading...</div>}>
            <HeaderHomeEight drawer={drawer} action={setDrawer.toggle} />
            <HeroHomeEight />
            <TestimonialHomeEight />
            <AboutHomeEight />
            <TeamHomeEight />
            </Suspense>
            <FooterHomeEight />
            <BackToTop className="back-to-top-8" />
        </>
    );
}

export default HomeEight;
