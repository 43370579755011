import React from 'react';

function ServicesAbout() {
    return (
        <>
            <section className="appie-services-2-area pt-10 pb-10" id="service">
                <div className="container">
                    
                    
                </div>
            </section>
        </>
    );
}

export default ServicesAbout;
