import React,{useEffect} from 'react';
import Thankyou from '../../../assets/images/new_3.png';
import { useHistory } from 'react-router-dom';

function Forms() {
    const [formData, updateFormData] = React.useState([]);
    const history = useHistory();

    useEffect(()=>{
        setTimeout(() => {
            history.push('/')
        }, 3000);
    },[])
    const handleChange = (e) => {
        updateFormData({
            ...formData,

            // Trimming any whitespace
            [e.target.name]: e.target.value.trim()
        });
    };
    return (
        <>
            <section className="contact-section pt-50">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="contact--info-area">
                                <h3>Get in touch</h3>
                                <p>Looking for help? Fill the form and start a new adventure.</p>
                                <div className="single-info">
                                    <h5>Headquaters</h5>
                                    <p>
                                        <i className="fal fa-home"></i>
                                        No. 10A, Vaigai Street Extension,Gurusamy Nagar,Sembakkam,
                                        <br /> Chennai-600073
                                    </p>
                                </div>
                                <div className="single-info">
                                    <h5>Phone</h5>
                                    <p>
                                        <i className="fal fa-phone"></i>
                                        73977 34625
                                        <br />
                                        73977 34629
                                        <br />
                                        95662 01323
                                    </p>
                                </div>
                                <div className="single-info">
                                    <h5>Email</h5>
                                    <p>
                                        <i className="fal fa-envelope"></i>
                                        info@mavvidyashram.com
                                        <br />
                                        mavvidyashram@gmail.com
                                    </p>
                                </div>
                                <div className="ab-social">
                                    <h5>Working Hours</h5>
                                    Morning session : 08.50 a.m. to 01.00 p.m.
                                    <br />
                                    Afternoon session : 01.30 p.m. to 04.30 p.m
                                    <br />
                                    Sunday: Closed
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="contact-form appie-section-title text-center">
                                <h3>Thank you for reaching out</h3>
                                <br/>
                                <img src={Thankyou} />
                                <br/><br/>
                                <p><b>NOTE: INCASE IF YOU WOULD HAVE APPLIED FOR BONAFIDE CERTIFICATE OR IT REQUEST NEXT STEP IS TO VISIT US TO GET A SIGNED HARDCOPY FOR THE SAME</b></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="bisylms-map">

            </div>
        </>
    );
}

export default Forms;
