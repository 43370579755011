import React from 'react';
import useToggle from '../../Hooks/useToggle';

function Smc() {
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
             <object width="100%" height="1000" data="https://mavvidyashram.com/p_documents/smc.pdf" type="application/pdf">   </object>

        </>
    );
}

export default Smc;
