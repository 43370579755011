import React from 'react';
import { Link } from 'react-router-dom';
import team1 from '../../assets/images/yoga.jpeg';
import team2 from '../../assets/images/karate.jpeg';
import team3 from '../../assets/images/skating.jpeg';
import team4 from '../../assets/images/swimming.jpeg';

function TeamAbout() {
    return (
        <>
            <section className="appie-team-area appie-team-about-area pb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Our features</h3>
                                <p></p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="appie-team-item appie-team-item-about mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="thumb">
                                    <img src={team4} alt="" />
                                 
                                </div>
                                <div className="content text-left">
                                    <h5 className="title">Swimming</h5>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="appie-team-item appie-team-item-about mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="thumb">
                                    <img src={team2} alt="" />
                                 
                                </div>
                                <div className="content text-left">
                                    <h5 className="title">Karate</h5>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="appie-team-item appie-team-item-about mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="thumb">
                                    <img src={team1} alt="" />
                                 
                                </div>
                                <div className="content text-left">
                                    <h5 className="title">Yoga</h5>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="appie-team-item appie-team-item-about mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="thumb">
                                    <img src={team3} alt="" />
                                 
                                </div>
                                <div className="content text-left">
                                    <h5 className="title">Skating</h5>
                                    
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className="team-btn text-center mt-50">
                                <Link className="main-btn" to={'facilities'}>Read more  <i className="fal fa-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default TeamAbout;
