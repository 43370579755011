import React from 'react';

function HeroAbout() {


    return (
        <>
            <div className="appie-about-top-title-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="appie-about-top-title">
                                {/* <h2 className="title">The leading people Management platform</h2>
                                <p>We offer you a panoply of cutting-edge options.</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="appie-about-page-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-about-page-content customLineHeight">
                                <h3 className="title">
                                    Growth & Achievements
                                </h3>

                                <br />
                                <p>
                                    “The purpose of education is to replace an empty mind with an open one”<br />– Malcolm S.
                                    <p>M.A.V. Vidyashram aims to impart quality education through the comprehensive and
                                        structured curriculum using innovative classroom methodologies while catering to a holistic allround development through extracurricular activities.</p>
                                    <p>We build a „student-centered‟ approach by offering a simulating environment in which
                                        students thrive by developing both their intellectual and social skills.</p>
                                    <p>Our school is striving hard to make the best possible efforts to inculcate strong values
                                        combining with academics and extra-curricular activities in the children converting every
                                        individual into a self-salient and independent citizen.</p>
                                    <br />
                                    “The term “too big fail” must be excised from our vocabulary”<br />– Jamie Dimon

                                    <p>To prove the above cited quote, let‟s see the glimpses on the growth of our school, so far.</p>
                                    <br />
                                    <p>M.A.V.Vidyashram was established in the year 2011 in Sembakkam, Chennai, by the
                                        visionary and founder Chairman Mr.M.Arulvendan out of his deep interest and concern to offer
                                        quality education in this part of the neighbourhood. This great educational institution began
                                        with a lot of humbleness shaping its future in this prestigious campus.</p>
                                    <p>It was started with just 30 students and 5 teachers. With the founder‟s untiring efforts and
                                        the hard work of various stakeholders comprising the teachers, students, parents and all the
                                        well-wishers, now the school has around 1,370 students, 66 academic staff members, 5 extracurricular masters, 6 admin staff and 16 multi task members on its rolls. During this period, the
                                        institution has witnessed unparalleled growth in the realm of course, infrastructure and
                                        affiliation in the year 2015. The school has so for the Board examination and secured 100%
                                        result. Our school has been upgraded from Secondary level to Senior Secondary level since the
                                        Academic year 2020 – 2021.</p>
                                </p>
                                {/* Correspondent Desk */}
                                <br />
                                <h4 >
                                    Tree plantation
                                </h4>
                                <br/>
                                <p>"Plant a tree and plant hope for future"</p>
                                
                                <p>As a step towards environment conservation, a significant way to inculcate rhythm of life in
                                    harsh hardness of nature, every year our students plant tree saplings not only to increase the
                                    green cover in the school campus and its neighborhood but also along the Kamarajapuram -
                                    Gowrivakkam stretch of East Tambaram – Velachery Main Road, since we are environment
                                    conscious group and plant loving people. In fact trees are almost a part of our institution and
                                    tree planting forms part of hands – on science based activities.</p>
                                <br />
                                {/* Academic achievements */}
                                <div>
                                            <h3 className="title">
                                                Achievements in Academics
                                            </h3>
                                            <br />
                                            <h4>2015 – 2016</h4>
                                            <br />
                                            <ul>
                                                <li>
                                                    Shrirang of G IV A was selected for 1stlevel in silver zone Olympiad at state level Rank 5 in
                                                    Science and Rank 10 in General Knowledge.</li>
                                                <li> Samyukthaa of Grade V was selected for 2nd level in silver zone Olympiad at state level
                                                    Rank 3 in Science and English
                                                </li>
                                            </ul>
                                            <br />
                                            <h4>2016 – 2017</h4>
                                            <br />
                                            <ul>
                                                <li>
                                                    Spell Bee International
                                                    *Our school was placed as "One of the top 10 schools of Kanchipuram District‟ in the year
                                                    2016 – 17 for receiving maximum gold medals.
                                                    The Gold medal winners were, <br />
                                                    <ul>

                                                    </ul>
                                                    <li>1. S. Deekshita of G II C</li>
                                                    <li>2. HawinKarthick Raja of G II B</li>
                                                    <li>3. Shlok Vishnu Savant of G III E</li>
                                                    <li>4. N.M. Nabanyasri of G IV C</li>
                                                    <li>5. N.P. Nithan of G IV B</li>
                                                    <li>6. S.K. Prithika of G IV A</li>
                                                    <li>7. Tanya Royson of G IV C</li>
                                                    <li>8. R. Vaishnavi of G IV A</li>
                                                    <li>9. Adityajha of G V B</li>
                                                    <li>10. Vidyavasistha of G V B</li>
                                                    <li>11. Dharuna of G VII A</li>
                                                </li>
                                            </ul>
                                            <br />
                                            <h4>2017 – 2018</h4>
                                            <br />
                                            <ul>
                                                <li>
                                                    The second batch of class X students appeared for the Board examination in March 2017 and
                                                    secured 100% result
                                                </li>
                                                <br />
                                                <h4>Phenomenal Achievements in the same year:</h4>
                                                <br />
                                                <ul>
                                                    <li>
                                                        The unique project [Theory / Science of flight] put up by our budding scientist Master P.
                                                        Nandhan of G III , during the ‘Little legends’ day - Science expo’ was a master piece of all
                                                        the projects of the year.</li>
                                                    <li>Master Dhanuesh of G IX took part in an on – the – spot “Basket of fire” Basketball League
                                                        Tournament and was adjudged as the “Most knowledgeable player of the Tournament”.</li>
                                                    <li>Master R.M. AravindValliappan of G VII was adjudged as the “Champion of Champions”
                                                        amongst around 2000 competitors in the "Vedic Maths‟ category at the 9th National Abacus
                                                        championship held on January 26, 2018 at Tirusoor, Kerala.
                                                    </li>
                                                    <br />
                                                    <h4>Science symposium</h4>
                                                    <br />
                                                    <li>
                                                        On October 27, 2018 our Grade X students took part and exhibited their innovative projects
                                                        at the science symposium held at Sai Tech Higher Secondary School. Our school secured
                                                        10th position among 70 schools.</li>
                                                    <li>11 students of G IX took part at the “TECH S2T” Science fair / Symposium held at Sai Ram
                                                        college, Chennai. 375 High schools and Higher secondary students from around 70 schools
                                                        competed in various competitions. Miss. SamyukthaaAravinth won the 2nd prize in "Poster
                                                        Presentation‟ on Astro physics in the G IX  to XII category.
                                                    </li>
                                                </ul>

                                </ul>
                                </div>

                                {/* Sports */}
                                <span>
                                    <br/>
                                    <br/> 
                                    <h3 className='title'>Achievements in sports and extracurricular Activities</h3>
                                    <br/>
                                    <p>“Sports do not build character they reveal it”. Our aim is to promote a broad – base of sports we have been providing the best coaching
in all the arenas.</p>
<br/>
<h4>Roller Skating:</h4>
<ul>
    <li>
    Master Ashwant of G III had participated in the group races at the open meet held at
Puducherry and won Silver medal.</li>
<li>He also bagged the medal in the Kancheepuram District Roller Skating competition held at J
J Indoor stadium, Chennai in 2018
    </li>
</ul>
<br/>
<h4>Canvas Art Competition</h4>
<br/>
<p>As part of "Art Gallery Exhibition – Workshop‟ Organized by Babajividyashram,
Solinganallur, Chennai, a Canvas Art competition was conducted on September 28, 2019. 11
students participated and Miss. Samyukthaa Aravinth won the First place in the Grade IX –
XII category, the medium of canvas / art being “Black and White”</p>
<br/>
<h4>Karate</h4>
<br/>
<ul>
    <li>
    19 students of our school participated at the SHOBUKI Chennai open National karate
championship Chennai on September 28 – 29, 2019 and won 1 gold medal, 1 silver and 3
Bronze medals</li>
<li>8 Students of our school participated at the CBSE cluster Level Inter – school judo
competition held from October 13, 2019 to October 15, 2019 at Telangana state and won 03
Bronze medals.</li>
<li>Around 60 students of our school Participated at the GIYKON Inter – School karate
Tournament held at Chennai on November 03, 2019 and won 14 gold medals, 10 silver and
12 Bronze medals.</li>
<li>Around 20 Students of our school participated at the 2nd international Isshinryu karate and
Kobudo championships held at Montfort indoor stadium, Chennai on December 08, 2019
and won 8 gold medals, 8 silver and 14 Bronze medals
    </li>
</ul>
<br/>
<h4>Yoga</h4>
<br/>
<ul>
    <li>
    32 students of our school participated at the 9th south Indian yogasana championship held at
the Ramakrishna Mission Higher secondary school, T.Nagar on July 28, 2019 and won many
prizes.</li>
<li>More than 50 students participated at the first All India Level Yogasana championship
organized by Vinasri yoga centre on February 2, 2020 our children not only won medals ( 8
gold, 13 silver and 20 Bronze ] but also won the “Overall Champitionship”
    </li>
</ul>
<br/>
<h4>Innovian Nandhan</h4>
<br/>
<ul>
    <li>
    Nandhan of G V D, has participated in Several Aero Events in many schools and colleges
such as Madras Institute of Technology (MIT), Anna University, PERI Institute of Technology,
Chennai Institute of Technology (CIT), Indian Institute of Technology (IIT), and Vellore
Institute of Technology (VIT).</li>
<li>He designed, built and piloted his own RC Aircraft models in all these events. His Cessna
Flight simulator in particular came in for much appreciation from many scholars including the
Head of Department (HOD) of Aerospace Engineering Madras Institute of Technology(MIT)
Dr.S.Tanigaiarasu.
    </li>
</ul>
<br/>
<h4>Remarkable Accomplishment:</h4>

<p>Every year we celebrate Annual day with great zeal and enthusiasm. In the same manner,
The 10th
Annual day was celebrated virtually with great éclat. That was our first ever Virtual
Gala event in order to kindle the spirit of togetherness and encourage students to exhibit their
talents through virtual platform. More than 900 students participated in the Grand Cultural
Festival and made the event a huge success</p>
                                </span>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HeroAbout;

