/* eslint-disable */
import React from 'react'
import {Link} from 'react-router-dom'

function Navigation() {
  return (
    <>
      <ul>
        <li>
        <Link to="/">Home</Link>
        </li>
        <li>
        <Link to="#">About Us  <i className="fal fa-angle-down" /></Link>
          
          <ul className="sub-menu">
            <li>
              <Link to="/management-desk">Management Desk</Link>
            </li>
            {/* <li>
              <Link to="/home-two">FAQs</Link>
            </li> */}
            <li>
              <Link to="/about-us">History</Link>
            </li>
            <li>
              <Link to="/growth-and-achievement">Growth & Achievement</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to="/facilities">Facilities & Features</Link>
        </li>
        <li>
          <a href="#">
            E-MAV <i className="fal fa-angle-down" />
          </a>
          <ul className="sub-menu">
            <li>
              <Link to="/bonafide">Bonafide Certificate</Link>
            </li>
            <li>
              <Link to="/leave-letter">Leave Letter</Link>
            </li>
            <li>
              <Link to="/update-form">Update Information</Link>
            </li>
            <li>
              <Link to="/it-form">IT Request</Link>
            </li>
            <li>
              <a target="_blank" href="https://mavvidyashram.com/findnumber">Find Number</a>
              
            </li>
          </ul>
        </li>
        <li>
          <a target="_blank" href="https://gallery.mavvidyashram.com">
            Gallery 
          </a>
        </li>
        <li>
          <a target="_blank" href="https://news.mavvidyashram.com">
            News 
          </a>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
     
    </>
  )
}

export default Navigation
