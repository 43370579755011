import React from 'react';
import workThumb from '../../assets/images/how-it-work-thumb.png';
import curriculum from '../../assets/images/curriculum.png';

function FeaturesComp() {
    return (
        <>
            <div className="appie-how-it-work-area pt-10 pb-10">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="how-it-work-thumb text-center">
                                <img src={workThumb} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="appie-how-it-work-content">
                                <h2 className="title">Features</h2>
                                <p>
                                    Salient features of (y)our school
                                </p>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="how-it-work-box">
                                            <span>1</span>
                                            <h5 className="title">
                                                Classes: From Pre-kg <br />to Grade – XII
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="how-it-work-box">
                                            <span>2</span>
                                            <h5 className="title">
                                                Co - education <br />system
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="how-it-work-box">
                                            <span>3</span>
                                            <h5 className="title">
                                                Affiliated with Central Board of Secondary <br />Education, New Delhi.
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="how-it-work-box">
                                            <span>4</span>
                                            <h5 className="title">
                                                An ISO : certified, <br /> accredited school
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="how-it-work-box">
                                            <span>5</span>
                                            <h5 className="title">
                                                Qualified and motivated teachers <br /> provide perfect blend of<br /> knowledge to the students.
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row align-items-center">
                        <div className='col-lg-12'>
                            <h2>Other features</h2>
                            <ol className='pt-20'>
                                <li className='pb-10'> - Our school provides all the modern facilities to enable the students to learn well and face
                                    the challenges of the competitive world.</li>
                                <li className='pb-10'> - We at M.A.V.Vidyashram create awareness of the rich tradition and culture of our
                                    country among students</li>
                                <li className='pb-10'> - We organize outdoor training programmes for teachers periodically and valuable field
                                    trips for students twice every year to enhance their knowledge.</li>
                                <li className='pb-10'> - Our school organizes morning assembly every week where students present News,
                                    Thought for the day, Interesting facts, Meaning for Thirukurals etc.,</li>
                                <li className='pb-10'> - We also impart moral teaching based on ethical values during the weekly “Value
                                    Education” period.</li>
                                <li className='pb-10'> - Special assemblies are also organized with cultural activities on special festivals and
                                    occasions to teach the importance of that event. (eg) Pongal celebration, Christmas
                                    celebration, Krishna Jayanthi celebration, Independence Day, Republic Day, Abdul
                                    Kalam’s Birthday and so on.</li>
                                <li className='pb-10'> - We encourage the students to bring out their imagination in art and craft too during their
                                    Art & Craft period.</li>
                                <li className='pb-10'> - Well-trained PET Masters train the students to have a fit and healthy life.</li>

                                <li className='pb-10'> - The School also conducts free medical check-ups and camps for all the students every
                                    year.</li>
                                <li className='pb-10'> - We also offer personalized Career Guidance & Counselling by professionals for the
                                    Secondary and Senior Secondary students periodically.</li>
                                <li className='pb-10'> - Further, our school facilitates intensive training inputs for improving reading, writing and
                                    speaking abilities of the students with regular tests and assignments.</li>
                            </ol>
                        </div>
                    </div>
                    <div className='row align-items-center pt-30'>
                        <img src={curriculum}/>
                    </div>
                </div>
            </div>

        </>
    );
}

export default FeaturesComp;
