import React from 'react';
import FacilitiesComp from './FacilitiesComp';

function HeroAbout() {
    return (
        <>
            <div className="appie-about-top-title-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="appie-about-top-title">
                                {/* <h2 className="title">The leading people Management platform</h2>
                                <p>We offer you a panoply of cutting-edge options.</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="appie-about-page-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-about-page-content">
                                
                                <p>
                                We at M.A.V. Vidyashram are very proud that we have created an excellent
ambience and learning environment, as it provides our students an opportunity to explore
and grow. As rightly said, “School is a building which has four walls with tomorrow inside”
we are focusing on the development of our students in all the fields - Academic Excellence
and Extracurricular Activities. Our aim is to guide each individual towards a confident,
resourceful, independent and happy adulthood, well qualified in every way.
                                </p>
                                <br/>
                                
                              
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HeroAbout;
