import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';

function FooterHomeEight() {
    return (
        <>
            <section className="appie-footer-area appie-footer-about-area appie-footer-8-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="footer-about-widget">
                                <div className="logo">
                                    <a href="#">
                                        <img src={logo} alt="" />
                                    </a>
                                </div>
                                <p>
                                M.A.V. VIDYASHRAM is established at Sembakkam (Chennai) to impact better education and to improve knowledge and skills of pupils. Attention to individuals student in ensure by restricting the number of students to an optimum level
                                </p>
                                {/* <a href="#">
                                    Read More <i className="fal fa-arrow-right"></i>
                                </a> */}
                                {/* <div className="social mt-30">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-facebook-f"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-pinterest-p"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-linkedin-in"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="footer-navigation">
                                <h4 className="title">M.A.V</h4>
                                <ul>
                                    <li>
                                        <Link to="/about-us">About Us</Link>
                                    </li>
                                    <li>
                                        <Link to="/facilities">Facilities & Features</Link>
                                    </li>
                                    <li>
                                        <Link to="/growth-and-achievement">Growth & Achievement</Link>
                                    </li>
                                    <li>
                                        <a href="https://news.mavvidyashram.com">News</a>
                                    </li>
                                    <li>
                                        <Link to="/contact">Contact</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                     
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-widget-info">
                                <h4 className="title">Get In Touch</h4>
                                <ul>
                                    <li>
                                        <a href="#">
                                            <i className="fal fa-envelope"></i> mavvidyashram@gmail.com
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fal fa-phone"></i> +91 95662 01323
                                        </a>
                                        <br/>
                                        <a href="#">
                                            <i className="fal fa-phone"></i>+91 7397734625
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fal fa-map-marker-alt"></i> No. 10A, Vaigai Street Extension,Gurusamy Nagar,Sembakkam,Chennai-600073
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="
                footer-copyright
                d-flex
                align-items-center
                justify-content-between
                pt-35
              "
                            >
                                
                                <div className="copyright-text">
                                    <p>Copyright © 2022 M.A.V Vidyashram All rights reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FooterHomeEight;
