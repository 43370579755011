import React from 'react';
import workThumb from '../../assets/images/how-it-work-thumb.png';
import celebrations from '../../assets/images/celebrations.png';
import facilities from '../../assets/images/facilities-1.png';
import facilities2 from '../../assets/images/facilities-2.png';
import facilities3 from '../../assets/images/facilities-3.png';
import facilities4 from '../../assets/images/facilities-4.png';
import facilities5 from '../../assets/images/facilities-5.png';
import facilities6 from '../../assets/images/facilities-6.png';

function FacilitiesComp() {
    return (
        <>
            <div className="appie-how-it-work-area pt-10 pb-10">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="how-it-work-thumb text-center">
                                <img src={facilities} alt="" />
                                
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="appie-how-it-work-content">
                                <h2 className="title">Facilities</h2>
                                <p>
                                Basically, facilities offered by a school enhances and promotes the health, behaviour,
engagement, learning, and growth of the students. We ensure that our students have best
learning experience in classrooms and in the campus.
                                </p>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="how-it-work-box">
                                            <span>1</span>
                                            <h5 className="title">
                                            Teaching <br/>with Technology
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="how-it-work-box">
                                            <span>2</span>
                                            <h5 className="title">
                                            A Library<br/> of Thoughts
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="how-it-work-box">
                                            <span>3</span>
                                            <h5 className="title">
                                            Medical Centre <br/>/ Infirmary
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="how-it-work-box">
                                            <span>4</span>
                                            <h5 className="title">
                                            Outdoor KG play area
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="how-it-work-box">
                                            <span>5</span>
                                            <h5 className="title">
                                            Science Laboratories
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="how-it-work-box">
                                            <span>6</span>
                                            <h5 className="title">
                                            Digital Technology Lab
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="how-it-work-box">
                                            <span>7</span>
                                            <h5 className="title">
                                            Math Lab
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="how-it-work-box">
                                            <span>8</span>
                                            <h5 className="title">
                                            Transport service
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="how-it-work-box">
                                            <span>9</span>
                                            <h5 className="title">
                                            Security System <br/>and CCTV
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="how-it-work-box">
                                            <span>10</span>
                                            <h5 className="title">
                                            Sports
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                    <div className='row align-items-center pt-20'>
                    <div className="col-lg-6">
                            <div className="how-it-work-thumb text-center">
                                <img src={facilities2} alt="" />
                                
                            </div>
                        </div>
                        <div className='col-lg-6'>
                        <h4  className='pb-20'>Teaching with Technology</h4>
                        <p>Digital projectors are now pairing the way seamlessly for teachers to concentrate on
teaching and for learners to learn effortlessly. Here, we engage the students with LED
projectors to create new learning experience.</p>
                        </div>
                        <div className='col-lg-12 pt-50'>
                        <h4 className='pb-20'>A Library for Thoughts</h4>
                        <p>The library maintains a supportive and a highly nurturing environment in building
knowledge bank for students. Our school library contains books, DVDs that cover a
spectrum of subjects and satisfies the students’ urge to learn. We highly encourage our
students to spend the library hours effectively, where they immerse themselves in the ocean
of books under a pleasant atmosphere. Our Library also has resources for continuing
professional development in the teaching staff of the school. We also have a kid’s library for
Kindergarten students and having more than 6700 books in the library.</p>
                        </div>
                        <div className='col-lg-6 pt-50'>
                        <h4 className='pb-20'>Medical Centre / Infirmary</h4>
                        <p>Students at M.A.V.Vidyashram are taken care when in need at the Medical Centre, which
houses students who are unwell. The sick room is always manned with a qualified and
professional nurse who will be available on site during the school hours, treats minor injuries,
provides first aid and maintains students health records.</p>
                        </div>
                        <div className="col-lg-6">
                            <div className="how-it-work-thumb text-center">
                                <img src={facilities3} alt="" />
                                
                            </div>
                        </div>
                        <div className='col-lg-6 pt-50'>
                        <h4 className='pb-20'>Outdoor KG Play Area</h4>
                        <p>A separate and secure, covered play area is used by our younger students during cooler
seasons. Soft play areas and equipment provide a variety of activities and opportunities for
exploration.</p>
                        </div>
                        <div className="col-lg-6">
                            <div className="how-it-work-thumb text-center">
                                <img src={facilities5} alt="" />
                                
                            </div>
                        </div>
                        <div className='col-lg-6 pt-50'>
                        <h4 className='pb-20'>Science Laboratories</h4>
                        <p>In M.A.V.Vidyashram, science zone is well-equipped centre with state - of – the art
laboratories for Physics, Chemistry, Biology and Environmental Sciences. Many concepts
that are taught in the classroom are reinforced in various ways at science zone. From
microscopes and anatomy models to various apparatuses and info graphic charts, the science
zone creates a stimulating environment for the curious minds to work on academic problems.</p>
                        </div>
                        <div className="col-lg-6">
                            <div className="how-it-work-thumb text-center">
                                <img src={facilities4} alt="" />
                                
                            </div>
                        </div>
                        <div className='col-lg-12 pt-50'>
                        <h4 className='pb-20'>Digital Technology Lab</h4>
                        <p>Due to the exponential development of the Information Technology field. It is a necessity
nowadays that the students must know to operate computer as early as possible. The school
has a well-resourced informatics lab linked through a dedicated hi – speed internet
connection. Students work in a variety of ways, using word processing, databases, graphic
presentations, power point and desktop publishing. The curriculum is also well – aligned to
their needs for progressive learning of computer operation.</p>
                        </div>
                        <div className='col-lg-12 pt-50'>
                        <h4 className='pb-20'>Math Lab</h4>
                        <p>Mathematics is a major form of communication in the modern world. Through our
manifold learning centre, we aim to help children enjoy the subject and develop confidence
in their ability to use it in a range of different settings. We would like our students to enjoy
learning math as much as they enjoy creating art. We ensure that the students are skilled at
critical thinking, evaluation and analysis.</p>
                        </div>
                        <div className='col-lg-12 pt-50'>
                        <h4 className='pb-20'>Security System and CCTV</h4>
                        <p>The safety and security of our students is our priority in school. In order to provide a
secure environment for our students, the school has taken the initiative to install CCTV
cameras in and around the school campus to ensure safety at all times.</p>
                        </div>
                        <div className='col-lg-6 pt-50'>
                        <h4 className='pb-20'>Sports</h4>
                        <p>Beyond the development of skills, our sporting facilities support students in their goals of
leading healthy and active lifestyles.</p>
<h4 className='pt-20 pb-20'>Swimming pool</h4>
<p>Knowing how to swim is more than a sport, it is a fundamental skill. In M.A.V.
Vidyashram’s swimming pool is open to students throughout the day. We take pride in
saying that our swimming pool is designed to meet all health and safety guidelines. Students
are always under watchful eyes of trained lifeguards and are strongly discouraged from
breaking pool rules. Qualified staff maintain a high degree of cleanliness and proper water
chemistry in our pool at all times. We assure the best swimming experience for everyone.</p>
                        </div>
                        <div className="col-lg-6">
                            <div className="how-it-work-thumb text-center">
                                <img src={facilities6} alt="" />
                                
                            </div>
                        </div>
                        <div className='col-lg-12 pt-50'>
                        <h4 className='pb-20'>Skating Academy</h4>
                        <p>Our school offers skating classes for all ages and levels. Our qualified instructors will
give step-by-step instruction in a supportive and supervised environment, enabling the
students to enjoy a lifetime of fun and exercise.</p>

                        </div>
                    </div>
                    <div className='row align-items-center pt-30'>
                        <img src={celebrations} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default FacilitiesComp;
