import React from 'react';

function Forms() {
    return (
        <>
            <section className="contact-section pt-50">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="contact--info-area">
                                <h3>Get in touch</h3>
                                <p>Looking for help? Fill the form and start a new adventure.</p>
                                <div className="single-info">
                                    <h5>Headquaters</h5>
                                    <p>
                                        <i className="fal fa-home"></i>
                                        No. 10A, Vaigai Street Extension,Gurusamy Nagar,Sembakkam,
                                        <br /> Chennai-600073
                                    </p>
                                </div>
                                <div className="single-info">
                                    <h5>Phone</h5>
                                    <p>
                                        <i className="fal fa-phone"></i>
                                        73977 34625
                                        <br />
                                        73977 34629 
                                        <br/>
                                        95662 01323
                                    </p>
                                </div>
                                <div className="single-info">
                                    <h5>Email</h5>
                                    <p>
                                        <i className="fal fa-envelope"></i>
                                        info@mavvidyashram.com 
                                        <br />
                                        mavvidyashram@gmail.com
                                    </p>
                                </div>
                                <div className="ab-social">
                                    <h5>Working Hours</h5>
                                    Morning session : 08.50 a.m. to 01.00 p.m.
                                    <br/>
                                    Afternoon session : 01.30 p.m. to 04.30 p.m
                                    <br/>
                                    Sunday: Closed
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="contact-form">
                                <h4>Let’s Connect</h4>
                                <p>Send us an email if you have any queries. We will get back to you shortly</p>
                                <form className="row">
                                    <div className="col-md-6">
                                        <input type="text" name="f-name" placeholder="First Name" />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" name="l-name" placeholder="Last Name" />
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Email Address"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="number"
                                            name="phone"
                                            placeholder="Phone Number"
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <input type="text" name="suject" placeholder="Subject" />
                                    </div>
                                    <div className="col-md-12">
                                        <textarea
                                            name="message"
                                            placeholder="How can we help?"
                                        ></textarea>
                                    </div>
                                    <div className="col-md-6 text-right">
                                        <input type="submit" name="submit" value="Send Message" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="bisylms-map">
               
            </div>
        </>
    );
}

export default Forms;
